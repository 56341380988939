import { BarChartParams, chartDataIsTuples } from "./bar-chart";

export default class BarChart {
  data: [string, number][];
  element: HTMLElement;

  constructor(params: BarChartParams) {
    this.data = parseData(params.data);
    this.element = params.element || document.getElementById('bar-chart');

    this.data.forEach(row => {
      const p = document.createElement('p');
      p.textContent = `${row[0]}: ${row[1]}%`;
      this.element.appendChild(p);
    });
  }
}

// Accept data in either an array format or an object format.
function parseData(data: BarChartParams['data']): [string, number][] {
  return chartDataIsTuples(data)
    ? data
    : data.map(({ name, value }) => [name, value]);
}
