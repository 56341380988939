const DEFAULT_COLOR = 'BC204B';
const translations: {[key: string]: string} = (window as any)['TRANSLATIONS'];

export interface BarChartParams {
  data: ChartDataParam;
  element: HTMLElement;
  color?: string;
}

/**
 * @example
 * [["achievement",11.0],["people_first",8.7]]
 */
type ChartDataParam = [string, number][] | { name: string, value: number }[];

export default class BarChart {
  data: [string, number][];
  element: HTMLElement;
  color: string;

  constructor(params: BarChartParams) {
    this.element = params.element ?? document.getElementById('bar-chart');
    this.color = params.color ?? DEFAULT_COLOR;

    if (!Array.isArray(params.data)) {
      this.data = [];
      return;
    }

    this.data = parseData(params.data);

    setTimeout(() => {
      google.load(
        "visualization",
        "1",
        { callback: this.drawChart, packages:["corechart"] }
      );
    }, 1);
  }

  drawChart = () => {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', 'Criteria');
    dataTable.addColumn('number', 'Priority');
    dataTable.addColumn({ type: 'string', role: 'tooltip' });
    dataTable.addColumn({ type: 'string', role: 'annotation' });

    this.translateRows(this.data).forEach(row => {
      dataTable.addRow(this.addAnnotations(row));
    });

    const chart = new google.visualization.ColumnChart(this.element);
    chart.draw(dataTable, {
      legend: 'none',
      vAxis: { baseline: 0 },
      series: [{ color: this.color }],
    });
  }

  addAnnotations([name, value]: [string, number]): [string, number, string, string] {
    return [name, value, `${name}: ${value}%`, `${value}%`];
  }

  // Hack alert: If there is a translation for the name (in the case of an
  // archetype name) then use the translation, otherwise just return the
  // untranslated name.
  translateRows(rows: [string, number][]): [string, number][] {
    return rows.map(([name, value]) => [translations[name] ?? name, value]);
  }
}

function parseData(data: ChartDataParam): [string, number][] {
  if (chartDataIsTuples(data)) { // data is array of arrays
    return data.map(([name, value]) => [name, Math.round(value)]);
  } else { // data is an array of objects
    return data.map(({ name, value }) => [name, Math.round(value)]);
  }
}

export function chartDataIsTuples(data: ChartDataParam): data is [string, number][] {
  return Array.isArray(data[0]); 
}
