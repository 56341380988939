// Setting up a URL to switch to a new locale server-side is not enough because
// the hash part of the URL must be included in the link too.
export function init() {
  const switcher = document.querySelector('.js-locale-switcher');
  if (!switcher) {
    console.error("missing js-local-switcher");
    return;
  }
  
  switcher.addEventListener('click', (event) => {
    // Don't follow the link
    event.preventDefault();
    setHrefs();
  });
}

// Update the hash location of the URL on every click.
function setHrefs() {
  const locales = document.querySelectorAll('.js-switch-locale');

  locales.forEach((el) => {
    const href = el.getAttribute('href');
    if (!href) {
      console.error("js-switch-local element missing href");
      return;
    }

    const hrefWithoutHash = href.split('#')[0];
    el.setAttribute('href', `${hrefWithoutHash}${location.hash}`);
  });
}
