// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Add global Ember dependencies
const win: any = window;
win["jQuery"] = require("jquery");
win["$"] = win["jQuery"];
win["_"] = require("underscore");

const BarChart =
  win["CLIENT_CONFIG"]["chart_rendering"] === "serialized"
    ? require("./fake-bar-chart").default
    : require("./bar-chart").default;

win["App"] = { BarChart };

require("./locale-switcher").init();

require("./vendor/bootstrap-dropdown");
require("./vendor/bootstrap-tooltip");
require("./vendor/bootstrap-popover");
require("./vendor/bootstrap-collapse");
